import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import "../../../App.css";
import MoreIcon from "@mui/icons-material/MoreVert";
import silogo from "../../../assets/images/si-logo.svg";
import Link from "@mui/material/Link";
import FavoriteTwoToneIcon from "@mui/icons-material/FavoriteTwoTone";
import { useHistory, browserHistory, useMatch, useRouteMatch, useLocation } from "react-router-dom";
import "../../../assets/css/custom.css"
let enableNewUI = process.env.REACT_APP_NEWUI_LW;

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      className="footerMenu"
      marginBottom="24px"
      {...props}
    >
      <br></br><br />
      Made with <FavoriteTwoToneIcon sx={{ color: "#820813" }} /> by<br></br>
      <Link color="inherit" target="_blank" href="https://servingintel.com/">
        <img className="silogo" src={silogo} alt="ServingIntel" />
      </Link>{" "}
    </Typography>
  );
}

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "360px",
  backgroundColor: "transparent !important",
  boxShadow: "none !important",
  textTransform: "none",
});

export default function BottomMenuNav() {
  const history = useHistory();
  const { state } = useLocation();
  console.log(state)
  return (
    <React.Fragment>
      <AppBar
        position="relative"
        id="footerMenu"
        sx={{
          boxShadow: 0,
          top: "auto",
          bottom: 0,
          backgroundColor: "#FEFEFE",
          borderTop: "1px solid #ebebeb",
          borderBottom: "1px solid #ebebeb",
          height: "120px",
          paddingBottom: "10px",
        }}
      >
        <Toolbar>
          {/* <IconButton
            color="inherit"
            className="btmLeftArrowHover"
            // href=""
            aria-label=""
            sx={{ zIndex: 9999, transform: "scale(1.5)", backgroundColor: "#005CB9", "&:hover": {backgroundColor: "#16497c"} }}   
            onClick={() =>{
              history.push({
                pathname: "/welcome",
                state
              });
            }}
             >         
          <ArrowBackTwoToneIcon sx={{ color:"#FFFFFF" }}/>
          </IconButton> */}
          <StyledFab style={{ width: "fit-content" }} id="nohover" disableautofocus disableenforcefocus>
            {enableNewUI == 0 ? (
              <Copyright position="centered"
                sx={{
                  top: "auto",
                  bottom: 0,
                  "&:hover": { backgroundColor: "none" },
                  "&:focus": { backgroundColor: "none" },
                  "&:active": { backgroundColor: "none" }
                }}
              />) : null}
          </StyledFab>
          <Box sx={{ flexGrow: 1 }} />
          {/* <IconButton  
            sx={ {zIndex: 99999, transform: "scale(1.5)", backgroundColor: "#005CB9", "&:hover": {backgroundColor: "#16497c"} }}     
            className="backArrowTopMenu" 
            color="inherit"
            href="/"
            aria-label=""
          >
            <LogoutTwoToneIcon sx={{ color:"#FFFFFF" }}/>
          </IconButton> */}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
