/*import "../../../../../App.css";*/
import React from 'react';
import "../../../../../assets/css/custom.css";
import {
  Container,
  Row,
  Col,
  Button,
  h5,
  p,
  ul,
  li,
  h1,
  ListGroup,
} from "react-bootstrap";
import { FaInstagramSquare, FaFacebookF } from "react-icons/fa";
import { ImYoutube2 } from "react-icons/im";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export const SocialConnect = () => {
  return (
    <div className="social-connect">
    <Container className="social-icons ">
        <Col xs={12} className="social-title">
           <h5 className="">
            {" "}
            Social Connect
            </h5>
        </Col>    
        <Col xs={12} className="social-icons icon">
        <ul>
            <li><a href="https://www.facebook.com/AJSliders"><FaFacebookF/></a></li>
            <li><a href="https://www.youtube.com/watch?v=9av_A6CEq_o"><ImYoutube2/></a></li>
            <li><a href="https://www.instagram.com/ajsliders/"><FaInstagramSquare/></a></li>
        </ul>
        </Col> 
     </Container>
    </div>
  );
};
