import "../../../App.css";
import { Container, Row, Col } from "react-bootstrap";
import LogoCenter from "../components/LogoCenter";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation, withRouter } from "react-router-dom";
import BlankSpace from "../components/BlankSpace";
import BottomNav from "../components/BottomNav";
//import ChangeTextSize from "components/changeText/ChangeTextSize";
import DrawerRight from "../components/DrawerRight";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea } from "@mui/material";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import BarTop from "../components/BarTop";
import BarBottom from "../components/BarBottom";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const enableRevenueCenter = process.env.REACT_APP_ENABLE_REVENUE_CENTER;
let enableNewUI = process.env.REACT_APP_NEWUI_LW;

export const Welcome = () => {
  const history = useHistory();
  const { state } = useLocation();
  const [servingPeriod, setServingPeriod] = useState([]);
  const [revenueCenter, setRevenueCenter] = useState([]);
  const [menuName, setMenuName] = useState([]);
  const [selectedRevenueCenter, setSelectedRevenueCenter] = useState(
    state?.revenueCenter || "none"
  );
  const [selectedServingPeriod, setSelectedServingPeriod] = useState("none");
  const [selectedMenuName, setSelectedMenuName] = useState(
    state?.menuName || "none"
  );
  const iframe = {
    width: "100%",
  };

  useEffect(() => {
    document.title = "Welcome";
    ReactGA.initialize("UA-172332025-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("pinCodeOk") !== "1") {
      //sessionStorage.setItem("categoryUrl", current_url);
      history.push("/");
    }
  }, []);

  useEffect(() => {
    setSelectedRevenueCenter(state?.revenueCenter || "none");
  }, [state]);

  //Get Revenue Center or Serving Period
  useEffect(() => {
    const url =
      enableRevenueCenter == 1
        ? "https://ecommv2.servingintel.com/items/revenue-center/" +
          serverId +
          "/" +
          siteId
        : "https://ecommv2.servingintel.com/items/serving-period/" +
          serverId +
          "/" +
          siteId +
          "/" +
          storeId;
    axios
      .get(url)
      .then((response) => {
        if (enableRevenueCenter == 1) {
          setRevenueCenter(response.data);
        } else {
          setServingPeriod(response.data);
        }
      })
      .catch((error) => {});
  }, []);

  //Get Revenue Center Menu
  useEffect(() => {
    const url =
      "https://ecommv2.servingintel.com/items/revenue-menu/" +
      serverId +
      "/" +
      siteId +
      "/" +
      selectedRevenueCenter;
    if (selectedRevenueCenter != "none") {
      axios
        .get(url)
        .then((response) => {
          if (response.data != "") {
            setMenuName(response.data);
          } else {
            history.push({
              pathname: "/menu",
              state: {
                servingPeriod: selectedServingPeriod,
                revenueCenter: selectedRevenueCenter,
                menuName: selectedMenuName,
              },
            });
          }
        })
        .catch((error) => {});
    }
  }, [selectedRevenueCenter]);

  return (
    <div className="Welcome modal-container">
      <LogoCenter selectedRevenueCenter={selectedRevenueCenter} />
      <BlankSpace />
      <DrawerRight />
      <Container fluid="md" className="welcomeCustom">
        <Row className="d-flex flex-wrap">
          {enableRevenueCenter == 1 && selectedRevenueCenter == "none"
            ? revenueCenter.map((item) => {
                return (
                  <>
                    <Col xs={12} sm={4} className="blankspace center">
                      <Card>
                        <CardActionArea
                          className="cardAreaMenu"
                          onClick={() => {
                            setSelectedRevenueCenter(item.RevenueName);
                            // history.push({
                            //   pathname: "/menu",
                            //   state: {
                            //     servingPeriod: selectedServingPeriod,
                            //     revenueCenter: item.RevenueName,
                            //   },
                            // });
                          }}
                        >
                          <Box
                            className="welcomeImage"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            maxHeight="250px"
                            overflow="hidden"
                          >
                            <CardMedia
                              component="img"
                              height="250"
                              image={"/static/" + item.RevenueName + ".png"}
                              alt={item.RevenueName}
                              sx={{
                                objectFit: "cover",
                              }}
                            />
                          </Box>
                          <CardContent sx={{ paddingTop: 0 }}>
                            <Typography
                              marginTop={4}
                              gutterBottom
                              variant="h4"
                              component="div"
                            >
                              {item.RevenueName}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Col>
                  </>
                );
              })
            : menuName &&
              menuName.map((item) => {
                return (
                  <>
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={4}
                      className="blankspace center"
                    >
                      {enableNewUI === "0" ? (
                        <Card>
                          <CardActionArea
                            className="cardAreaMenu"
                            onClick={() => {
                              if (item.ShowMenu == 1) {
                                setSelectedMenuName(item.MenuName);
                                history.push({
                                  pathname: "/menu",
                                  state: {
                                    servingPeriod: selectedServingPeriod,
                                    revenueCenter: selectedRevenueCenter,
                                    menuName: item.MenuName,
                                  },
                                });
                              } else {
                                Swal.fire({
                                  title: "Store Message",
                                  text:
                                    "This menu is only available to order between " +
                                    item.StartTime +
                                    " to " +
                                    item.EndTime,
                                  icon: "info",
                                  confirmButtonText: "OK",
                                });
                              }
                            }}
                          >
                            <div className="welcomeImage">
                              <CardMedia
                                component="img"
                                height="140"
                                image={"/static/" + item.MenuName + ".png"}
                                alt={item.RevenueName}
                              />
                            </div>
                            <CardContent sx={{ paddingTop: 0 }}>
                              <Typography
                                marginTop={8}
                                gutterBottom
                                variant="h4"
                                component="div"
                              >
                                {item.PeriodName
                                  ? item.PeriodName
                                  : item.ServingPeriodName
                                  ? item.ServingPeriodName
                                  : item.MenuName}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      ) : (
                        <Card style={{ backgroundColor: "transparent" }}>
                          <CardActionArea
                            className="cardAreaMenu"
                            onClick={() => {
                              if (item.ShowMenu == 1) {
                                setSelectedMenuName(item.MenuName);
                                history.push({
                                  pathname: "/menu",
                                  state: {
                                    servingPeriod: selectedServingPeriod,
                                    revenueCenter: selectedRevenueCenter,
                                    menuName: item.MenuName,
                                  },
                                });
                              } else {
                                Swal.fire({
                                  title: "Store Message",
                                  text:
                                    "This menu is only available to order between " +
                                    item.StartTime +
                                    " to " +
                                    item.EndTime,
                                  icon: "info",
                                  confirmButtonText: "OK",
                                });
                              }
                            }}
                          >
                            <CardMedia
                              style={{
                                borderRadius: "50%",
                                paddingTop: "100%",
                              }}
                              image={"/static/" + item.MenuName + ".png"}
                              alt={item.MenuName}
                            />
                            <CardContent>
                              <Typography
                                className="CenterName"
                                component="div"
                                variant="h1"
                                style={{ lineHeight: ".90", fontWeight: "400" }}
                              >
                                {item.PeriodName
                                  ? item.PeriodName
                                  : item.ServingPeriodName
                                  ? item.ServingPeriodName
                                  : item.MenuName}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      )}
                    </Col>
                  </>
                );
              })}

          {selectedRevenueCenter != "none" && siteId == "216" ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', marginTop: '2rem' }}>
              <iframe
                src="https://v2stoneridge.servingintel.com/qsr-compact.html"
                title="qsr"
                style={{ height: '100%', width: '100%' }}
              ></iframe>
            </div>
          ) : null}
        </Row>
      </Container>
      <BlankSpace />
      <BlankSpace />
      <BottomNav sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} />
      <BarTop />
      <BarBottom />
    </div>
  );
};
