import React, { useEffect, useState } from "react";
import { IconButton, Badge } from "@material-ui/core";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import { useSelector, useDispatch } from "react-redux";
import { CartDrawer } from "./CartDrawer";
import { useRouteMatch, useHistory } from "react-router-dom";
import { numberWithCommas } from "../../../utils/numbersWithComma";
import BackspaceIcon from "@material-ui/icons/Backspace";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Icon } from "@mui/material";
import { setTotalPrice } from "../../../redux/cartReducer/actions";
export const Cart = ({}) => {
  const match = useRouteMatch();
  const history = useHistory();
  const { cartItems } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.public);
  const dispatch = useDispatch();
  const [totalItems, setTotalItems] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    const checkCart = localStorage.getItem(`ajsliders_cart`);
    if (checkCart) {
      dispatch({ type: "SET_CART", payload: JSON.parse(checkCart) });
    } else {
      dispatch({ type: "SET_CART", payload: [] });
    }
  }, []);

  useEffect(() => {
    if (cartItems.length) {
      localStorage.setItem(`ajsliders_cart`, JSON.stringify(cartItems));
      let total = 0;
      let totalPrice = 0;
      cartItems.forEach((item) => {
        let mod_price = 0;
        item.modifiers.forEach((mod) => (mod_price += mod.DefaultPrice));
        total += item.quantity;
        totalPrice +=
          item.quantity * (item.DefaultPrice / 100 + mod_price / 100);
      });
      dispatch(setTotalPrice(totalPrice));
      setTotalItems(total);
    } else {
      setTotalItems(0);
      dispatch(setTotalPrice(0));
      localStorage.removeItem(`ajsliders_cart`);
    }
  }, [cartItems]);

  return (
    <React.Fragment>
      <div>
        <IconButton
          style={{ opacity: "0" }}
          onClick={
            () =>
              //user ?
              history.push(`/checkout`)
            // : history.push("/sign-in", {
            //   restaurantId: match.params.restaurantId,
            // })
          }
        >
          <Badge color="secondary" badgeContent={totalItems}>
            <Icon color="action" fontSize="large">
              <img style={{ height: "100%", width: "80%" }} src="check.png" />
            </Icon>
          </Badge>
        </IconButton>
      </div>
      <CartDrawer open={openDrawer} setOpen={setOpenDrawer} />
    </React.Fragment>
  );
};
