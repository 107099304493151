import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../redux/publicReducer/actions";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Typography, IconButton } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { setOrderSession } from "../../redux/cartReducer/actions";
import { useCookies } from "react-cookie";
const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const site = process.env.REACT_APP_SITE;

export const Worldpay = (props) => {
  const { user } = useSelector((state) => state.public);
  const {
    totalPriceItems,
    tips,
    cartItems,
    promoCode,
    totalDiscount,
    rewardsBalance,
    giftCardBalance,
    orderSession,
  } = useSelector((state) => state.cart);
  const { orderProcessingDate, selectedAddress, orderProcessingType } =
    useSelector((state) => state.public);

  const [pid, setPid] = useState("");
  const [orderId, setOrderId] = useState("");
  const [userId, setUserId] = useState("");
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const [cookieOrderId, setCookieOrderId, removeCookieOrderId] = useCookies([
    "",
  ]);
  const tax = props.location.state.tax;
  const salesTax = parseFloat(totalPriceItems * tax);
  const couponAmount = promoCode ? parseFloat(totalDiscount) : 0.0;

  const subTotal = promoCode
    ? parseFloat(totalPriceItems) - parseFloat(totalDiscount)
    : totalPriceItems;

  const total =
    parseFloat(tips) + parseFloat(subTotal) + parseFloat(subTotal * tax);

  const setCookie = (key, value) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie =
      key + "=" + value + ";expires=" + expires.toUTCString() + ";path=/";
  };

  async function initVantivForm(orderId = 0) {
    if (totalPriceItems) {
      //const p_url ="http://localhost/servingintel_api/public/payment/initialize";
      const p_url =
        "https://ecommv2.servingintel.com/payment/initialize/" + siteId;

      let returnURL = site + "/thankyou.php";
      let balance = 0;
      if (rewardsBalance > 0) {
        balance = parseFloat(rewardsBalance);
        returnURL =
          "https://ecommv2.servingintel.com/rewards/" +
          orderId +
          "/partial/" +
          siteId;
      }

      if (giftCardBalance > 0) {
        balance = parseFloat(giftCardBalance);
        returnURL =
          "https://ecommv2.servingintel.com/giftcards/" +
          orderId +
          "/partial/" +
          siteId;
      }

      axios
        .post(p_url, {
          OrderID: "001",
          Total: total - balance,
          Tax: tax,
          ReturnURL: returnURL,
          RestaurantName: "AJ Sliders",
          RestaurantAddress: "2075 Prairie Street, St. Charles, IL",
          ZipCode: "60174",
        })
        .then(({ data }) => {
          /*console.log(data, "DATA PID");*/
          setPid(data);
          localStorage.setItem("pid", data);

          if (data != "" && orderId == 0) {
            setCookie("sic_email", selectedAddress.email);
            setCookie("sic_phone", selectedAddress.phone);
            setCookie("sic_name", selectedAddress.first_name);
            setCookie("sic_address", JSON.stringify(selectedAddress));
            //const url ="http://localhost/servingintel_api/public/postpayment/setOrder";
            const url =
              "https://ecommv2.servingintel.com/postpayment/setOrder/" +
              siteId +
              "?server_id=" +
              serverId;
            const d = new Date(orderProcessingDate);
            axios
              .post(url, {
                cartItems: cartItems,
                totalPriceItems: totalPriceItems,
                total: total,
                subTotal: subTotal,
                tips: tips,
                tax: tax,
                promoCode: promoCode,
                pid: data,
                order_id: !!sessionStorage.getItem("order_id")
                  ? sessionStorage.getItem("order_id")
                  : orderId,
                orderProcessingType: orderProcessingType ? "pickup" : "dine-in",
                orderProcessingDate: {
                  date:
                    d.getFullYear() +
                    "/" +
                    (d.getMonth() + 1) +
                    "/" +
                    d.getDate(),
                  time: d.getHours() + ":" + d.getMinutes(),
                },
                selectedAddress: selectedAddress,
                instructions: sessionStorage.getItem("instuctions"),
              })
              .then((response) => {
                // console.log(response);
                sessionStorage.setItem("order_id", response.data.order_id);
                setCookieOrderId("orderId", response.data.order_id, {
                  path: "/",
                });
                sessionStorage.setItem("user_id", response.data.user_id);
              })
              .catch((error) => {
                // console.log(error);
              });
          }

          dispatch(setLoading(false));
        });
    }
  }

  async function setInitOrderSession(paymentType) {
    let paymentMethod = {};
    if (paymentType == "rewards") {
      paymentMethod.type = "Rewards";
      paymentMethod.balance = parseFloat(rewardsBalance);
    } else {
      paymentMethod.type = "Gift Cards";
      paymentMethod.balance = parseFloat(giftCardBalance);
    }

    let payments = [
      {
        "payment-type": paymentMethod.type,
        amount: paymentMethod.balance,
        tip: 0,
      },
      {
        "payment-type": "Credit Card",
        amount: total - rewardsBalance,
        tip: tips,
      },
    ];

    // console.log('paymentsInfo', payments)

    const params = {
      cartItems: cartItems,
      selectedAddress: selectedAddress,
      total: total,
      tips: tips,
      pid: "",
      payments: payments,
      subTotal: subTotal,
      orderProcessingType: orderProcessingType ? "pickup" : "dine-in",
      orderProcessingDate: orderProcessingDate,
      instructions: sessionStorage.getItem("instuctions"),
      orderId: 0,
    };

    // if (paymentMethod.type == "Rewards") {
    //   return await rewardsService.setSessionOrders(params);
    // } else if (paymentMethod.type == "Gift Cards") {
    //   return await giftcardService.setSessionOrders(params);
    // }
  }

  async function main(paymentType) {
    await setInitOrderSession(paymentType).then((res) => {
      dispatch(setOrderSession(res.data));
      setOrderId(res.data.order_id);
      setUserId(res.data.user_id);
      initVantivForm(res.data.order_id);
    });
  }

  useEffect(() => {
    dispatch(setLoading(true));
    // dispatch(setOrderSession(0));
    if (rewardsBalance > 0) {
      main("rewards");
    } else if (giftCardBalance > 0) {
      main("giftcards");
    } else {
      initVantivForm();
    }
  }, [totalPriceItems]);

  return (
    <div style={{ marginLeft: "20px" }}>
      <br />
      <IconButton onClick={() => history.goBack()}>
        <ArrowBackIosIcon />
      </IconButton>

      {pid && (
        <iframe
          align="right"
          id="ctl00_MainContent_ifrm"
          src={`https://hc.mercurypay.com/Checkoutiframe.aspx?pid=${pid}`}
          height="600px"
          frameBorder="0"
          style={{ textAlign: "left", overflow: "hidden", width: "100%" }}
        ></iframe>
      )}
    </div>
  );
};
