import "../../../App.css";
import { Container, Row, Col, Button, h5, p, h2, div } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";
// import center from '../../../assets/css/custom.css';
import { MenuNavigation } from '../components/MenuNavigation';
import {Footer} from '../components/Footer';
import aboutimg1 from "../../../assets/images/about-img-01.jpg";
import aboutimg2 from "../../../assets/images/about-img-02.jpg";
import aboutimg3 from "../../../assets/images/about-img-03.jpg";
import React, { useEffect } from 'react'
import ReactGA from 'react-ga';
import { withRouter } from "react-router-dom";

export const About = () => {   
    
useEffect(() => {
    document.title = "About Us"
    ReactGA.initialize('UA-172332025-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
}, [])    
        
  return (
      
    <div className="About">
    <MenuNavigation/>
      <div className="HeaderImg about-info">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={8}>
              <h5 className="white" >Purpose: to love and serve to others through excellence in every encounter. </h5>
              <br/> 
              <h1 className="white">
                Changing Lives, 
              </h1>
              <h1 className="white">  
                One Slider at a time.
              </h1>
            </Col>
            <Col xs={8} className="">
              <h5 className="white">
                {" "}
                AJ Sliders is an ambiguous restaurant venture focused on providing great-tasting food that is low-carb, gluten-free, wheat-free, sugar-free, and soy-free while serving the community as a 'business as a mission,' sharing the love of Jesus Christ.
              </h5>
              <br/>
              <h5 className="white">
                Please watch these three videos to learn more about AjSliders. 
              </h5>
              <Button className="btn-red margin-top-10 margin-bottom-10" href="/menu" >
                  Order Now{" "}
                </Button>
            </Col>

           <col className="whitespace"></col>
          </Row>
        </Container>

       
      </div>
       <Container>
          <Row>
           <div className="col-lg-4">
                    <div class="about-info-left">
                        <h5>Highlights from the Business</h5>
                        <ul>
                            <li>Family-owned & Operated</li>
                            <li>Discounts available</li>
                            <li>Gluten-free friendly</li>
                            <li>Outdoor seating</li>
                            <li>Grab-and-go</li>
                            <li>Catering Services</li>
                        </ul>
                    </div>
            </div>
                  <div  className="col-lg-8">
                    <div  className="about-info-right">
                        <h1>AJ Sliders Why - Our Mission</h1>
                        <h6> In this video, Lance Bell explaines the calling and the purpose of the business named AJ Sliders. </h6>
                        <br/>
                        <h4> About the Business </h4>
                        <h6>AJ Sliders is an ambiguous restaurant venture focused on providing great-tasting food that is low-carb, gluten-free, wheat-free, sugar-free, and soy-free while serving the community as a 'business as a mission,' sharing the love of Jesus Christ.</h6>
                        
                    </div>
                    <div  className="inner-about-gallery margin-top-30 ">
                            <div className="col-lg-12">
                                <div  className="col-lg-3 col-md-3 float-left">
                                    <img src={aboutimg1} />
                                </div>
                                <div  className="col-lg-3 col-md-3 float-left">
                                    <img src={aboutimg2} />
                                </div>
                                <div  className="col-lg-3 col-md-3 float-left">
                                    <img src={aboutimg3} />
                                </div>
                               
                                 
                                
                            </div>
                        </div>
                </div>
            <Col xs={12} className="whitespace">
              <h2 className=" dark">The Why of AJ Sliders - Purpose</h2>
            </Col>
            <Col xs={10} className=" title">
              <h5 className="">
                {" "}
                AJ Slider's purpose is to love and serve others through
                excellence in every encounter,. Changing lives, one slider at a
                time!
              </h5>
            </Col>
           
            <div className="col-lg-12 full-width">
              <ReactPlayer
                className=" about video"
                url="https://www.youtube.com/watch?v=f4F9kUTmluY"
              />
            </div>

            <Col xs={12} className="whitespace ">
              <h2 className=" dark ">The Why of AJ Sliders - The Food</h2>
            </Col>
            <Col xs={10} className=" title">
              <h5 className="">
                {" "}
                Customer focus has been expanded to serve those with celiac
                disease, gluten intolerance, and dairy/lactose intolerance.
              </h5>
            </Col>

          
            <div className="col-lg-12 full-width">
              <ReactPlayer
                className=" about video "
                url="https://www.youtube.com/watch?v=wHIk_-ZVAmw"
              />
            </div>
            
            <Col xs={12} className="whitespace">
              <h2 className=" dark ">
                The Why of AJ Sliders - The People
              </h2>
            </Col>
            <Col xs={10} className=" title">
              <h5 className="">
                {" "}
                In this video, Lance Bell explains the purpose of the business -
                serving people and impacting the world for Jesus Christ.
              </h5>
            </Col>

           <div className="col-lg-12 full-width">

              <ReactPlayer
                className=" about video"
                url="https://www.youtube.com/watch?v=ddpU4TWiGYs"
              />
            </div>

            <col className="whitespace"></col> 
          </Row>
        </Container>
        <Footer/>
    </div>

  );
};

export default withRouter(About);