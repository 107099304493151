import {
  SET_CART,
  SET_TOTAL_PRICE,
  SET_TIP,
  SET_SELECTED_MODE_PAYMENT,
  SET_PROMO_CODE,
  SET_ORDER_SESSION,
  SET_PAYMENT_OPTIONS,
  SET_REWARDS_BALANCE,
  SET_TOTAL_DISCOUNT,
  SET_GIFT_CARD_BALANCE
} from "./constants";

export function setCart(payload) {
  return { type: SET_CART, payload };
}

export function setTotalPrice(payload) {
  return { type: SET_TOTAL_PRICE, payload };
}

export function setTip(payload) {
  return { type: SET_TIP, payload };
}

export function setModeOfPayment(payload) {
  return {
    type: SET_SELECTED_MODE_PAYMENT,
    payload,
  };
}

export function setPromoCode(payload) {
  return {
    type: SET_PROMO_CODE,
    payload
  }
}

export function setTotalDiscount(payload) {
  return {
    type: SET_TOTAL_DISCOUNT,
    payload
  }
}

export function setOrderSession(payload) {
  return {
    type: SET_ORDER_SESSION,
    payload
  }
}

export function setPaymentOptions(payload) {
  return {
    type: SET_PAYMENT_OPTIONS,
    payload
  }
}

export function setRewardsBalance(payload) {
  return {
    type: SET_REWARDS_BALANCE,
    payload
  }
}

export function setGiftCardBalance(payload) {
  return {
    type: SET_GIFT_CARD_BALANCE,
    payload
  }
}


