import "../../../App.css";
import { Container, Row, Col } from "react-bootstrap";
import LogoCenter from "../components/LogoCenter";
import React, { useCallback, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation, withRouter } from "react-router-dom";
import BlankSpace from "../components/BlankSpace";
import BottomNav from "../components/BottomNav";
//import ChangeTextSize from "components/changeText/ChangeTextSize";
import DrawerRight from "../components/DrawerRight";
import {
  Avatar,
  CircularProgress,
  Container as Cont,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea, IconButton, Toolbar, Grid } from "@mui/material";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { useCookies } from "react-cookie";
import { ChevronLeft } from "@material-ui/icons";
import TransactionsTable from "../components/TransactionsTable";
import { stringAvatar, toCurrency } from "utils/helpers";
import {
  CreditScore,
  AccountBalanceWalletRounded,
  AccountCircleRounded,
  EmailRounded,
  LocalPhoneRounded,
  Person,
} from "@mui/icons-material";
import { createTheme } from "@material-ui/core";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const enableRevenueCenter = process.env.REACT_APP_ENABLE_REVENUE_CENTER;

export const Profile = () => {
  const history = useHistory();
  const { state } = useLocation();
  const [{ customerDetails }, setCookieSICName, removeCookieSICName] =
    useCookies(["customerDetails"]);
  const [servingPeriod, setServingPeriod] = useState([]);
  const [revenueCenter, setRevenueCenter] = useState([]);
  const [menuName, setMenuName] = useState([]);
  const [selectedRevenueCenter, setSelectedRevenueCenter] = useState(
    state?.revenueCenter || "none"
  );
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  // console.log(customerDetails, "cust");
  return (
    <div className="Welcome modal-container">
      <LogoCenter
        selectedRevenueCenter={selectedRevenueCenter}
        hasUserInfo={false}
      />
      <BlankSpace />
      <DrawerRight />

      <Container fluid="md" className="welcomeCustom">
        {customerDetails?.FirstName ? (
          <Box>
            <Cont
              maxWidth="lg"
              sx={{
                padding: 2,
              }}
            >
              <Box>
                <IconButton
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  <ChevronLeft />
                </IconButton>
              </Box>
              <Stack direction="row" gap={2}>
                <Avatar
                  style={{
                    cursor: "pointer",
                    width: 100,
                    height: 100,
                  }}
                  alt={`${customerDetails?.FirstName} ${customerDetails?.LastName} Avatar`}
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/dependent-images/" +
                    `${customerDetails?.FirstName} ${customerDetails?.LastName}`
                      .replace(" ", "-")
                      .toLowerCase() +
                    ".png"
                  }
                  {...stringAvatar(
                    `${customerDetails?.FirstName} ${customerDetails?.LastName}`
                  )}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Stack direction="row" gap={1}>
                    <Grid container>
                      <Grid item md={1}>
                        <AccountCircleRounded />
                      </Grid>
                      <Grid item md={5}>
                        <Typography
                          sx={{ fontWeight: "600", marginRight: "25px" }}
                        >
                          Full Name
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography>{`${customerDetails?.FirstName} ${customerDetails?.LastName}`}</Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                  {customerDetails?.Email === "" ? null : (
                    <Stack direction="row" gap={1}>
                      <Grid container>
                        <Grid item md={1}>
                          <EmailRounded />
                        </Grid>
                        <Grid item md={5}>
                          <Typography
                            sx={{ fontWeight: "600", marginRight: "25px" }}
                          >
                            Email
                          </Typography>
                        </Grid>
                        <Grid item md={6}>
                          <Typography>{customerDetails?.Email}</Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                  )}
                  {customerDetails?.Phone === "" ? null : (
                    <Stack direction="row" gap={1}>
                      <Grid container>
                        <Grid item md={1}>
                          <LocalPhoneRounded />
                        </Grid>
                        <Grid item md={5}>
                          <Typography
                            sx={{ fontWeight: "600", marginRight: "25px" }}
                          >
                            Phone Number
                          </Typography>
                        </Grid>
                        <Grid item md={6}>
                          <Typography>{customerDetails?.Phone}</Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                  )}
                  <Stack direction="row" gap={1}>
                    <Grid container>
                      <Grid item md={1}>
                        <AccountBalanceWalletRounded />
                      </Grid>
                      <Grid item md={5}>
                        <Typography
                          sx={{ fontWeight: "600", marginRight: "25px" }}
                        >
                          Account Balance
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography>
                          $
                          {Math.abs(
                            (customerDetails.CreditLimit -
                              customerDetails.AvailableCredit) /
                              100
                          ).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                  {/* <Stack direction="row" gap={1}>
                  <Grid container>
                    <Grid item md={1}>
                  <CreditScore />
                    </Grid>
                    <Grid item md={5}>
                  <Typography sx={{ fontWeight: "600", marginRight: "25px" }}>
                    Credit Limit
                  </Typography>
                    </Grid>
                    <Grid item md={6}>
                    <Typography>
                      {toCurrency(customerDetails?.CreditLimit / 100)}
                    </Typography>
                    </Grid>
                  </Grid>
                  </Stack> */}
                </Box>
              </Stack>
            </Cont>
          </Box>
        ) : (
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Container>
      <BlankSpace />
      <BlankSpace />
      <BottomNav sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} />
      {/* <ChangeTextSize /> */}
      {/* <ScrollToDown /> */}
    </div>
  );
};
