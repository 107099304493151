import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Paper,
  InputBase,
  IconButton,
  useMediaQuery
} from "@material-ui/core";
import { useRouteMatch, useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import { NavBar } from "../navigation/NavBar";
import { Fragment } from "react";
import { BottomNavRestaurant } from "./Restaurant";
import { useSelector } from "react-redux";

export const Main = () => {
  useEffect(() => {
    document.title = "Gluten-Free Restaurant";
  }, []);

  const match = useRouteMatch();
  const [categories, setCategories] = useState([]);
  const isMobile = useMediaQuery("(max-width:600px)");
  const { user } = useSelector((state) => state.public);

  return (
    <Fragment>
      <NavBar />
      <div style={{ margin: "1rem 0rem" }} />

      <Container>
        {user && (
          <Typography variant="subtitle1" style={{ color: "gray" }}>
            Hi {user.name}
          </Typography>
        )}
        <Typography variant="h5">
          What would you like to order today?
        </Typography>
        <Paper
          style={{
            padding: "0rem 1rem",
            margin: "1rem 0rem",
            display: "flex",
            alignItems: "center",
            borderRadius: "15px"
          }}
          elevation={0}
        >
          <InputBase
            placeholder="or type what you are looking"
            style={{ width: "100%" }}
          />
          <IconButton>
            <SearchIcon />
          </IconButton>
        </Paper>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            overflowX: "auto",
            padding: "2rem 0rem"
          }}
        >
          {categories.map((category) => {
            return <CategoryModel product={category} />;
          })}
        </div>
      </Container>
      {isMobile && <BottomNavRestaurant />}
    </Fragment>
  );
};

export const CategoryModel = ({ product }) => {
  const history = useHistory();
  const match = useRouteMatch();
  return (
    <Paper
      style={{
        borderRadius: "15px",
        minHeight: "30vh",
        minWidth: "200px",
        width: "200px",
        // backgroundImage: `url(${product.image})`,
        backgroundColor: "white",
        margin: "0rem 6px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-start",
        paddingBottom: "2rem",
        paddingLeft: "2rem"
      }}
      onClick={() =>
        history.push(`/menu/${match.params.restaurantId}`, {
          category: product.name
        })
      }
    >
      <Typography
        style={{
          // color: "white",
          fontWeight: 800
        }}
        variant="h5"
      >
        {product.name}
      </Typography>
    </Paper>
  );
};
