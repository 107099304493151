import React from "react";
// import "../../../App.css";
import Logo from "../../../assets/images/logo.png";
import ordernowimgr from "../../../assets/images/ordernow-imgr.svg";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { useMediaQuery } from "@material-ui/core";

import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import parseHtml from "html-react-parser";

export const MenuNavigation = () => {
  const history = useHistory();
  const [cookieSICName, setCookieSICName, removeCookieSICName] = useCookies([
    "sic_name",
  ]);

  const isMobile = useMediaQuery("(max-width:100px)");
  return (
    <>
      <Navbar bg="light" variant="light" expand="lg" sticky="top">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Brand href="/welcome">
          <img className="Checkout-logo" src={Logo} alt="AjSliders" />
        </Navbar.Brand>

        {/*<Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/">Home</Nav.Link>
            {
              <Nav.Link
                href="#"
                onClick={() => {
                  history.push("/about");
                }}
              >
                About Us
              </Nav.Link>
            }
            <Nav.Link
              href="#"
              onClick={() => {
                history.push("/menu");
              }}
            >
              Order Now
            </Nav.Link>
            <Nav.Link
              href="#"
              onClick={() => {
                history.push("/team");
              }}
            >
              Team
            </Nav.Link>
            <Nav.Link
              href="#"
              onClick={() => {
                history.push("/contact");
              }}
            >
              Contact
            </Nav.Link>*/}
        {/*<Nav.Link href="#">Gift Cards</Nav.Link>
            <Nav.Link href="#">Team</Nav.Link>
            <Nav.Link href="#">Join Our Loyalty Program</Nav.Link>*/}
        {/*</Nav>
          <h5 className="white topbtn">
            {!isMobile
              ? cookieSICName.sic_name
                ? parseHtml(
                    "Welcome back <b>" + cookieSICName.sic_name + "!</b>"
                  )
                : null
              : null}
          </h5>
          {/* <a href="/menu" className="topbtn">
            <img src={ordernowimgr} />
          </a> */}
        {/*</Navbar.Collapse>*/}
      </Navbar>
    </>
  );
};
