import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function PhoneCard() {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          width="auto"
          image="/static/call-toll-free.png"
          alt="Phone Us 24/7"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Phone Us 24/7
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            +1 707-754-3377
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}